import { Route, Routes } from "react-router-dom";
import Auth from "./components/Auth";
import Main from "./components/Main";

const publicRoutes=[
    {
        path:"/Main",
        Component: Main
    },
    /*{
        path:"/Tutorial",
        Component: TutorialPage
    },*/
    {
        path:'/',
        Component:Auth
    },
];
const AppRouter=()=> {
  return (
    <Routes>
      {publicRoutes.map(({path, Component}) =>
      <Route key={path} path={path} element={<Component/>}/>
      )}
      <Route path='*' element={<Auth/>} />
    </Routes>
  )
}

export default AppRouter;