import axios from "axios"
import { createEffect } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"

export const GetWebHook = createEffect(async()=>{
 
    const res = await axios.get(`${defaultPath}/api/moysklad/get_webhook/${getAccauntId()}`)
 
    return res.data
})

export const CreateWebHook = createEffect(async(type:"material" |"tech_card" |"order" | "none" )=>{
   if(type === 'none'){
    await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"material"})

    await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"tech_card"})

    await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"order"})
   }else if(type === "material"){
      await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"material"})
   }else if(type === "tech_card"){
      await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"tech_card"})
   }else if(type === "order"){
      await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"order"})
   }
 })

 export const DeleteWebHook = createEffect(async()=>{

    await axios.post(`${defaultPath}/api/moysklad/delete_webhook/${getAccauntId()}`,{ webhook:"material"})

    await axios.post(`${defaultPath}/api/moysklad/delete_webhook/${getAccauntId()}`,{ webhook:"tech_card"})

    await axios.post(`${defaultPath}/api/moysklad/delete_webhook/${getAccauntId()}`,{ webhook:"order"})
    
 })