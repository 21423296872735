import { createEffect, createStore } from "effector"
import { getAccauntId } from "./InfoContext"
import { defaultPath } from "./Auth"
import axios from "axios"


export const GetStatus = createEffect(async()=>{
 
    const res = await axios.get(`${defaultPath}/api/moysklad/ms_states/${getAccauntId()}`)
 
    return res.data
    //return 'ok'
 })
 export const $states= createStore<any>(null)
  .on(GetStatus.doneData, (_,res)=> res)