import axios from "axios";
import { createEffect, createStore } from "effector";

import { getAccauntId } from "./InfoContext";

export const defaultPath = "https://moisklad.service.proplan.work/backend";

export const AuthRequest = createEffect(
    async ({ login, password }: { login: string; password: string }) => {
        const body = {
            // "account_id":getAccauntId(),
            cond_login: login,
            cond_password: password,
        };

        try {
            const res = await axios.post(
                `${defaultPath}/api/moysklad/cond_user/${getAccauntId()}`,
                body
            );

            return res.data;
        } catch (error: any) {
            if (error.response.data.status) {
                return 0;
            }
        }
        //return 'ok'
    }
);

export const $auth = createStore<any>(null).on(
    AuthRequest.doneData,
    (_, res) => res
);
