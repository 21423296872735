import axios from "axios"
import { createEffect, createStore } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"


export const GetOrganizations = createEffect(async()=>{
 
    const res = await axios.get(`${defaultPath}/api/moysklad/ms_organizations/${getAccauntId()}`)
 
    return res.data

 })
 export const $organizations = createStore<any>(null)
  .on(GetOrganizations.doneData, (_,res)=> res)