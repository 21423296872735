import './css/uikit.css'
import './App.css';
import { useUnit } from 'effector-react';
import { $auth } from './store/Auth';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppRouter from './AppRouter';
import { GetInfoContext } from './store/InfoContext';
import { $LogPass, GetLogPas } from './store/LogPas';
import { message } from 'antd';

function App() {
  const auth = useUnit($auth)
  const logPas = useUnit($LogPass)
  const navigate = useNavigate()
  const [params] = useSearchParams()
  console.log(auth)

  useEffect(()=>{
    navigate('/')
    const key = params.get('contextKey')
    if(key)
    GetInfoContext(key).then(()=>{
      GetLogPas().then((res)=>{
        if(res.cond_login && res.cond_password){
          navigate('/Main')
        }
      })
    })
  },[])

  useEffect(()=>{
    if(auth === 0){
      message.error("Неверный логин или пароль")
      navigate('/')
    }else if(auth){
      message.success("Вы Успешно авторизованы")
      navigate('/Main')
    }
  },[auth])

  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
