import React, { useState } from 'react'
import { AuthRequest } from '../store/Auth'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'

const Auth = () => {
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const navigate = useNavigate()

  const login = () => {
    if(email && password){
      AuthRequest({login:email,password})/*.then(()=>{
        alert("Вы Успешно авторизованы")
        navigate('/Main')
      }).catch((error)=>{
        alert(error.response.data.status)
      })*/
    }else{
      message.error("Введите что нибудь")
    }
  }
  return (
    <div className='ui-kit'>
        <div className="container">
            <div className='content-auth'>
                <input className="ui-input" type="text" name="ui-input" placeholder="Почта" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                <input className="ui-input" type="text" name="ui-input" placeholder="Пароль"value={password} onChange={(e)=>setPassword(e.target.value)}/>
                <button className="button--success" style={{padding:'8px'}} onClick={login}>Авторизоваться</button>
            </div>
        </div>
   </div>
  )
}

export default Auth