import axios from "axios"
import { createEffect, createStore } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"


export const GetSklads = createEffect(async()=>{
 
    const res = await axios.get(`${defaultPath}/api/moysklad/ms_stores/${getAccauntId()}`)
 
    return res.data
    //return 'ok'
 })
 export const $scklads = createStore<any>(null)
  .on(GetSklads.doneData, (_,res)=> res)
 /*export const $pickedSklad = createStore<any>(null)
 .on(GetSklads.doneData, (_,res)=> res)*/