import axios from "axios"
import { createEffect, createStore } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"


export const UpdateLogPas = createEffect(async({login,password}:{login:string,password:string})=>{
    const body ={
      cond_login:login,
      cond_password:password
    }

      const res = await axios.patch(`${defaultPath}/api/moysklad/cond_user/${getAccauntId()}`,body)
 
      return res.data

 })
 
 export const GetLogPas = createEffect(async()=>{
 
   const res = await axios.get(`${defaultPath}/api/moysklad/cond_user/${getAccauntId()}`)
 
   return res.data
   // return 'ok'
 })
 
 export const $LogPass = createStore<any>(null)
 .on(GetLogPas.doneData,(_,res)=>res)