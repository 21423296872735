import axios from "axios"
import { createEffect } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"

export const FirstLaunch = createEffect(async(params:any)=>{


 
    const res = await axios.post(`${defaultPath}/api/moysklad/first_launch/${getAccauntId()}`,params)
 
    return res.data
 })