import axios from "axios"
import { createEffect, createStore } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"
//import { $pickedSklad } from "./GetSklads"


export const SaveSetting = createEffect(async({skladMaterial,skladProduct,status,organization}:{skladMaterial:string,skladProduct:string,status:string[],organization:string})=>{

    const body ={
        states : status,
        //order_finish_status_id : statusMc ,
        material_store_id:skladMaterial,//"Здесь id склада",
        product_store_id: skladProduct,
        organization_id : organization
        /*material_id:material,//"Здесь id категории для материалов",
        product_id:product,//"Здесь id категории для продукции"
        state_id: state*/
    }
 
    const res = await axios.post(`${defaultPath}/api/moysklad/user_conf/${getAccauntId()}`,body)
 
    return res.data
    //return 'ok'
 })

 export const patchImport = createEffect(async(bool:boolean)=>{

 
    const res = await axios.patch(`${defaultPath}/api/moysklad/user_conf/${getAccauntId()}`,{active:bool})
 
    return res.data
    //return 'ok'
 })

 export const GetSetting = createEffect(async()=>{
 
     const res = await axios.get(`${defaultPath}/api/moysklad/user_conf/${getAccauntId()}`)
 
     return res.data
    //return 'ok'
 })

 export const $settings = createStore<any>(null)
 .on(GetSetting.doneData, (_,res)=>res)

