import axios from "axios"
import { createEffect, createStore } from "effector"
import { defaultPath } from "./Auth"


export const GetInfoContext = createEffect(async(key:string)=>{
 
    const res = await axios.get(`${defaultPath}/api/moysklad/get_context?contextKey=${key}`)

    return res.data
   //return 'ok'
})

export const $infoContext = createStore<any>(null)
.on(GetInfoContext.doneData, (_,res)=> {
    if(res.errors){
        return null
    }else{
        return res
    }
})

export const getAccauntId =()=> $infoContext.getState().accountId